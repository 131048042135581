import { Auth } from 'aws-amplify';
import getConfig from 'next/config';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { userFetchResponse, userLogoutResponse } from 'store/actions/userReducers';
import { QueryClient } from '@tanstack/react-query';
import { z } from 'zod';
import { AppointmentSchema, UserSchema } from 'vl-common/src/schemas/shared';
import { PolicySchema } from 'vl-common/src/schemas/shared/policy';
import qs from 'query-string';
import * as actionTypes from './actionTypes';
import { fetchData, requestSuccess, unpackJson } from './action';

// If this returns undefined it means the user is not logged in or there has been a Network failure.
// In both cases there's no recovery action and the user needs to be pushed back to the login page.
// (The user will *not* have been warned if there has been a networking problem).
export async function tryGetUser(source_url: string) {
  try {
    const tokens = await Auth.currentSession();
    const token = tokens.getIdToken().getJwtToken();
    const userGuid = tokens.getIdToken().payload.sub;
    const { publicRuntimeConfig } = getConfig();
    const { API_URL } = publicRuntimeConfig;
    const url = new URL(`${API_URL}/users/user/${userGuid}${source_url ? `?source_url=${source_url}` : ''} `);

    const ret = await fetch(url.toString(), {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });

    if (ret.ok && ret.status === 200) {
      return await unpackJson(ret, UserSchema);
    }
  } catch (_) {
    // fall through
  }

  return undefined;
}

export const userSendToken = (userGuid: string, token: string) => async (dispatch) => {
  await dispatch(userLogout());

  const user: CognitoUser = await Auth.signIn(userGuid);

  // @ts-ignore
  if (user && user?.challengeParam.requiredField === 'token') {
    const challengeResponse = await Auth.sendCustomChallengeAnswer(user, token);

    if (challengeResponse && challengeResponse.challengeParam.requiredField === 'code2') {
      return user;
    }
  }

  throw dispatch({ type: actionTypes.AUTH_LOGIN_FAIL, user }, false);
};

export const userHasLoggedOut = () => (dispatch) =>
  fetchData({ params: 'users/logout/APPUSER', method: 'PUT', postBody: {} })(dispatch, () => {}, null);

export const userLogout = (queryClient?: QueryClient) => async (dispatch) => {
  await Auth.currentSession().catch(() => {});

  // this needs to be actioned first in order to stop corrupt requests being made. See VL2-2174
  // JAE this stops you from being able to login twice
  // dispatch(requestSuccess(actionTypes.AUTH_SIGNED_OUT));

  await dispatch(userHasLoggedOut())
    .then((logoutResponse) => {
      const userIsUsingSso = logoutResponse.logout;
      if (userIsUsingSso) {
        const { logout } = logoutResponse;
        logout.logout_urls.every(async (logoutUrl) => {
          try {
            const res = await fetch(logoutUrl, {
              method: 'GET',
              headers: {
                'Cache-Control': 'no-store'
              }
            });
            const blob = await res.blob();
            const urlObject = URL.createObjectURL(blob);
            const logoutIframe = document.createElement('iframe');
            logoutIframe.setAttribute('src', urlObject);
            logoutIframe.setAttribute('style', 'position: absolute;width:0;height:0;border:0;');
            document.body.appendChild(logoutIframe);
          } catch {
            // Best effort until we get proper URLs from AXA
          }
          return true;
        });
      }
    })
    .catch();

  try {
    await Auth.signOut({ global: true });
  } catch (e) {
    await Auth.signOut().catch(() => {});
  }

  // remove the user data from Redux store
  dispatch(userFetchResponse(actionTypes.AUTH_SUCCESS, null, null, false));

  // clear the user from the redux store
  dispatch(requestSuccess(actionTypes.AUTH_LOGOUT_SUCCESS, { user: null }));
  if (queryClient) {
    queryClient
      .getQueriesData(['shared-websocket'])
      .filter(([, qd]) => qd)
      .forEach(([, qd]: any) => {
        console.log('Closing shared-websocket', qd);
        qd.close();
      });
    queryClient
      .getQueriesData(['useAppointmentEvents'])
      .filter(([, qd]) => qd)
      .forEach(([, qd]: any) => {
        console.log('Closing useAppointmentEvents web socket', qd);
        qd.close();
      });
    queryClient.clear();
  }

  return dispatch(userLogoutResponse());
};

export const userNewPassword = (password, userobj) => {
  const options = {
    params: 'new-password',
    method: 'POST',
    nobearer: true,
    postBody: {
      userobj,
      password
    },
    actionType: actionTypes.AUTH_LOGIN_NEWPASSWORD_SUCCESS,
    errorType: actionTypes.AUTH_LOGIN_NEWPASSWORD_FAIL
  };
  return fetchData(options);
};

export const checkPassword = (password: string) => {
  const options = {
    params: 'check-password',
    method: 'PUT',
    nobearer: true,
    postBody: {
      pass: password
    }
  };
  return fetchData(options);
};

export const userResetPassword = (email) => {
  const { publicRuntimeConfig } = getConfig();
  const { APP_CLIENT_ID } = publicRuntimeConfig;
  const options = {
    params: 'forgot-password',
    method: 'POST',
    nobearer: true,
    postBody: {
      ClientId: APP_CLIENT_ID,
      Username: email
    }
  };
  return fetchData(options);
};

export const userResetPasswordConfirm = (request) => {
  const options = {
    params: 'reset-password',
    method: 'POST',
    nobearer: true,
    postBody: request,
    actionType: actionTypes.AUTH_LOGIN_RESETPASSWORD_CONFIRM_SUCCESS,
    errorType: actionTypes.AUTH_LOGIN_RESETPASSWORD_CONFIRM_FAIL
  };
  return fetchData(options);
};

// User Appointment

type GetUserAppointmentRequest = {
  page?: number;
  rows?: number;
  sort?: string;
  order?: string;
  case?: number;
  client_case_id?: string;
  patient?: string;
  clinician?: string;
  date_start?: string;
  date_end?: string;
  week_offset?: number;
  completed?: string;
  status?: string;
  active?: string;
  cancelled?: boolean;
  appt_id?: number;
  upcoming?: boolean;
  cat_code?: string;
  cat_name?: string;
  area_code?: string;
  area_name?: string;
  clin_type?: string;
  client_code?: string;
  pat_guid?: string;
  created_from?: string;
  updated_from?: string;
  rescheduled?: boolean;
  team?: boolean;
  call_type?: string;
};

export const getUserAppointment = (
  type: 'USER_HISTORY_APPOINTMENT' | 'USER_UPCOMINGHISTORY' | 'USER_APPOINTMENTS' | 'USER_DIARY',
  request: GetUserAppointmentRequest
) => {
  const options = {
    params: `booking/appointments/APPUSER?${qs.stringify(request)}`,
    actionType: `${type}_SUCCESS`,
    errorType: `${type}_FAIL`
  };
  return fetchData(options, z.array(AppointmentSchema));
};

export const getUserAppointmentWithBreaks = (type, request) => {
  const queryString = Object.keys(request)
    .map((key) => `${key}=${request[key]}`)
    .join('&');
  const options = {
    params: `clinicians/diary/APPUSER?${queryString}`,
    actionType: `${type}_SUCCESS`,
    errorType: `${type}_FAIL`
  };
  return fetchData(options);
};

/* Timeline Appointment History */
export const userTimelineHistory = (type, caseID) => {
  const options = {
    params: `users/timeline/APPUSER?case_id=${caseID}`,
    actionType: `${type}_SUCCESS`,
    errorType: `${type}_FAIL`
  };
  return fetchData(options);
};

export const getAvatar = (ID: string) => {
  const options = {
    params: `avatar?user_guid=${ID}`
  };
  return fetchData(options);
};

export const getSignature = (ID) => {
  const options = {
    params: `signature?user_guid=${ID}`
  };
  return fetchData(options);
};

/* User Policy */
export const acceptUserPolicy = (request) => {
  return fetchData({
    params: 'users/policy',
    method: 'POST',
    postBody: request,
    actionType: 'USER_POLICY_ACCEPTED_SUCCESS',
    errorType: 'USER_POLICY_ACCEPTED_FAIL'
  });
};

export const userPolicy = (type: string) => {
  const options = {
    params: 'users/policy/APPUSER',
    actionType: `${type}_SUCCESS`,
    errorType: `${type}_FAIL`
  };
  return fetchData(options, z.array(PolicySchema));
};

export const patientCasePolicies = (patient_guid) => {
  const options = {
    params: `users/policy/${patient_guid}/CASE`,
    METHOD: 'GET'
  };
  return fetchData(options);
};

export const allPatientPolicies = () =>
  fetchData(
    {
      params: 'user-policies',
      nobearer: true,
      method: 'GET'
    },
    z.object({
      policies: z.array(PolicySchema)
    })
  );

export const createSelf = (
  first,
  last,
  email,
  password,
  captcha,
  self_refer = false,
  clientId = 2,
  type = 'CREATE_SELF'
) => {
  const options = {
    params: 'create-self',
    method: 'POST',
    nobearer: true,
    actionType: `${type}_SUCCESS`,
    errorType: `${type}_FAIL`,
    postBody: {
      first_name: first,
      last_name: last,
      email,
      password,
      token: captcha,
      client_id: clientId,
      self_refer
    }
  };
  return fetchData(options);
};

export const getUserTourData = () => {
  const options = {
    params: 'users/tour/APPUSER',
    method: 'GET'
  };
  return fetchData(options);
};
