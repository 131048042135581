// @ts-check
import 'ALIASED-antd/dist/antd.variable.css';
import 'survey-core/defaultV2.min.css';
import 'react-quill/dist/quill.snow.css';
import 'react-phone-number-input/style.css';
// @ts-ignore
import '@static/app.scss';
import '@static/calendar.css';
import '@static/slick.css';
import '@static/helper.css';
import 'react-image-crop/dist/ReactCrop.css';
import 'vl-common/src/components/BookAppointment/calendar.css';
import 'vl-common/src/components/TimeLine/provider-card.css';
import 'vl-common/src/components/CustomSurvey/customWidgets/ImageCrop.css';
import 'vl-common/assets/sass/app/app.scss';
import 'vl-common/assets/sass/app/add-patient.css';

// eslint-disable-next-line no-unused-vars
import * as actions from '@actions';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { Auth } from 'aws-amplify';
import { Provider, useDispatch } from 'react-redux';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { QueryParamProvider } from 'use-query-params';
import ReactGA from 'react-ga4';
import IdleTimer from 'react-idle-timer';
import initializeStore from '@store';
import { maybeRedirectToSetup } from '@hooks/useInitialisedStore';
import useRuntimeConfig, { getDynamicConfig, getUpdatedConfig } from '@vl-core/hooks/useConfig';
import getConfig from 'next/config';
import useMounted from '@hooks/useMounted';
import LogRocket from 'logrocket';
import * as actionTypes from '@actionTypes';
import { ThemeContextWrapper } from '@lib/context/theme';
import { AppointmentContextWrapper } from 'vl-common/src/lib/context/Appointment';
import { ConfigContext } from 'vl-common/src/hooks/Runtime';
import Footer from '@components/Layouts/Footer/Footer';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import CookieContainer from '@components/CookieConsent/CookieContainer';
import DynamicStylesheet from '@components/DynamicStylesheet/DynamicStylesheet';
import checkWebSockets from '@components/PermissionsCheck/checkWebSockets';
import AppointmentWait from '@components/AppointmentWait/AppointmentWait';
import { CancelledAppointmentsProvider } from '@hooks/useCancelledAppointments';
import { useSafariPrompts } from '@hooks/useSafariPrompts';
import { useIsPWAEnabled } from '@hooks/useIsPWAEnabled';
import { initialise as initialise_i18n } from 'lib/i18n';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import useIsRestricted from '@hooks/useIsRestricted';
import useAuth from '@vl-core/useAuth';
import useRedirectToBuildPath from 'vl-common/src/hooks/useRedirectToBuildPath';
import { HarReadyProvider, useHarReady } from 'vl-common/src/hooks/useHARReady';
import { resetHARServiceWorkerAsRequired } from 'vl-common/src/hooks/useHAR';
import { showLogoutAlert } from 'vl-common/src/services/service.api';
import { getUser } from 'store/actions/userAccess';
import { userFetchResponse } from 'store/actions/userReducers';
import { MockWebSocketWithSubscriptions } from '@hooks/useSharedAppointmentWebSocket';
import { MockWebSocket } from '@hooks/useAppointmentEvents';
import { DismissibleToast } from '@components/DismissableToast';
import { usePleaseLogInAndRedirect } from '@hooks/usePleaseLogIn';
import { NotificationProvider } from '../contexts/NotificationContext/NotificationContext';
import configureAmplify from '../helpers/configureAmplify';

resetHARServiceWorkerAsRequired();

const { publicRuntimeConfig } = getConfig();

const relativisePathname = (pathname: string) => {
  const firstSegment = pathname.split('/')[1];

  const isStoryBranch =
    firstSegment.startsWith('vl') && (firstSegment.endsWith('story') || firstSegment.endsWith('bug'));

  if (isStoryBranch) {
    return `/${pathname.split('/').slice(2).join('/')}`;
  }

  return pathname;
};

// keep the access token updated
const checkSession = async (router, dispatch) => {
  if (Auth.authenticatedUser || window.location.href.includes('har=')) return true;

  // const now = new Date();
  // console.log('Checking session ', now.toUTCString());
  try {
    await Auth.currentAuthenticatedUser({ bypassCache: true });
  } catch (error) {
    const path = relativisePathname(window.location.pathname);
    const unauthenticatedRoutes = [
      '/login',
      '/wellbeinghub',
      // JAE this an authenticate path
      // '/create-account',
      '/forgot-password',
      '/patients/register',
      '/patients/legal',
      '/survey',
      '/floating-video',
      '/regulatory',
      '/events-test',
      '/accessibility',
      '/linklogin',
      '/nhslink',
      '/hbsbvt'
    ];

    if (!unauthenticatedRoutes.find((url) => path.startsWith(url))) {
      // eslint-disable-next-line no-console
      console.debug(`${new Date().toString()} no session, "${path}"`);
      console.error(error);
      router.push('/login');

      // avoid a race condition
      const timedLogout = async () => {
        try {
          await Auth.currentAuthenticatedUser({ bypassCache: true });
        } catch {
          dispatch(actions.userLogout());
          Auth.signOut({ global: true }).catch();
          sessionStorage.clear();
          if (path !== '/') {
            showLogoutAlert(
              'Timed logout. Failed to validate current user. Please contact support with a screenshot of this page.'
            );
          }
        }
      };

      setTimeout(timedLogout, 300);

      return false;
    }
  }

  return true;
};

const debouncedCheckSession = debounce(checkSession, 1000);

const guestAccessAllowedRoutes = [
  '/login/',
  '/linklogin/',
  '/nhslink/',
  '/wellbeinghub/',
  // JAE this an authenticate path.
  // '/create-account',

  '/forgot-password/',
  '/policy/',
  '/patients/',
  '/patients/face2face/',
  '/patients/session/',
  '/patients/assessments/',
  '/patients/choose/',
  '/patients/register/',
  '/patients/legal/',
  '/patients/support/',
  '/survey/',
  '/floating-video/',
  '/regulatory/',
  '/events-test/',
  '/accessibility/'
];

const WrappedHBSApp = ({ Component, queryClient, componentProps }) => {
  useRedirectToBuildPath();
  const dispatch = useDispatch();
  const router = useRouter();
  const match = router.asPath.match(/[^?]+/);
  // remove trailing slash
  const pathname = match ? match[0] : router.asPath;
  const { user } = useAuth();
  const userGuid = useRef(user?.user_guid);
  const { LOG_ROCKET_APP_ID, LOG_ROCKET_INPUT_SANITIZER = false, TWILIO_BACKEND_URL } = useRuntimeConfig();
  const isRestricted = useIsRestricted(false);

  const [, setPleaseLogIn] = usePleaseLogInAndRedirect();

  useEffect(() => {
    if (
      user?.user_type_code === 'PATIENT' &&
      isRestricted &&
      !guestAccessAllowedRoutes.includes(`${pathname}/`.replaceAll('//', '/'))
    ) {
      setPleaseLogIn({
        pleaseLogIn: true,
        pleaseLogInRedirect: '/patients'
      });
    }
  }, [pathname, isRestricted, user, setPleaseLogIn]);

  useSafariPrompts();

  useEffect(() => {
    if (LOG_ROCKET_APP_ID && user) {
      LogRocket.init(LOG_ROCKET_APP_ID, { dom: { inputSanitizer: LOG_ROCKET_INPUT_SANITIZER } });

      const { user_type_code, first_name, last_name, tel_mobile_no, email, user_guid } = user;
      const traits = {
        email,
        name: `${first_name} ${last_name}`,
        id: user_guid,
        user_type_code,
        tel_mobile_no
      };

      LogRocket.identify(email, traits);

      dispatch({ type: actionTypes.LOG_ROCKET_INITIALISED });
    }
  }, [LOG_ROCKET_APP_ID, LOG_ROCKET_INPUT_SANITIZER, user, dispatch]);

  // Whenever the user_guid changes, reset the cache so there's no danger that cached data
  // bleeds from one user to another.
  // NB - normally I'd use useEffect() for this sort of thing but that runs *after* rendering
  // which means that some child components may have already initiated react-query requests.
  if (userGuid.current !== user?.user_guid) {
    // eslint-disable-next-line no-console
    console.log('Resetting react-query cache on user change');

    if (queryClient) {
      queryClient
        .getQueriesData(['shared-websocket'])
        .filter(([, qd]) => qd)
        .forEach(([, qd]) => {
          console.log('Closing shared-websocket', qd);
          qd.close();
        });
      queryClient
        .getQueriesData(['useAppointmentEvents'])
        .filter(([, qd]) => qd)
        .forEach(([, qd]) => {
          console.log('Closing useAppointmentEvents web socket', qd);
          qd.close();
        });

      queryClient.clear();
    }
    userGuid.current = user?.user_guid;
  }

  useEffect(() => {
    if (router) {
      const { NODE_ENV, TEST_RANDOM_FAILURES } = publicRuntimeConfig;

      if (NODE_ENV === 'development' && TEST_RANDOM_FAILURES) {
        delete publicRuntimeConfig.RANDOM_FAILURE_PROBABILITY;

        TEST_RANDOM_FAILURES.toString()
          .split(/[,;]/)
          .map((spec) => spec.toString().split('='))
          .filter((pair) => pair[0] === router.pathname)
          .forEach(([, probability]) => {
            publicRuntimeConfig.RANDOM_FAILURE_PROBABILITY = Number(probability);
          });

        if (publicRuntimeConfig.RANDOM_FAILURE_PROBABILITY) {
          console.log('pathname =', router.pathname, publicRuntimeConfig.RANDOM_FAILURE_PROBABILITY);
        }
      }

      debouncedCheckSession(router, dispatch);

      // 150 seconds === half the Cognito token expiry
      const timer = setInterval(() => checkSession(router, dispatch), 150 * 1000);

      return () => clearInterval(timer);
    }

    return () => {};
  }, [router, dispatch]);

  if (user?.user_guid) checkWebSockets(TWILIO_BACKEND_URL);

  return <Component {...componentProps} />;
};

function useStoreAndMaybeRedirect(amplifyConfigured, router) {
  const store = useMemo(initializeStore, []);
  const [storeReady, setStoreReady] = useState(false);

  // if there is a current session (i.e. user already logged in) then redirect to standard setup pages as required
  useEffect(() => {
    if (store && amplifyConfigured) {
      console.log('Calling Auth.currentSession');
      Auth.currentSession()
        .then(() => {
          const { dispatch } = store;
          return maybeRedirectToSetup(dispatch, window.location.pathname, (path) => {
            window.location.replace(path);
          });
        })
        .catch((reason) => {
          console.log({ reason });
          const { dispatch } = store;
          checkSession(router, dispatch);
        })
        .finally(() => {
          setStoreReady(true);
        });
    }
  }, [store, amplifyConfigured]);

  return { store, storeReady };
}

declare global {
  interface Window {
    checkExpireSession: () => boolean;
  }
}

// Loads up the user record into Redux if in HAR mode. Assumes HAR is all hooked up.
const MaybeLoadUser = () => {
  const { user } = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchUser = async () => {
      const { data: vlUser } = await getUser(Auth.authenticatedUser, '', dispatch);

      await dispatch(userFetchResponse(actionTypes.AUTH_SUCCESS, vlUser, '', false));
    };

    if (!user && Auth.authenticatedUser) {
      fetchUser().then();
    }

    // disable WebSockets
    MockWebSocket.MOCKED_WEB_SOCKETS = true;
    MockWebSocketWithSubscriptions.MOCKED_WEB_SOCKETS = true;
  }, [dispatch, user]);

  return null;
};

const HTMLHead = ({ stonlyTourActive }: { stonlyTourActive: boolean }) => {
  const isPWAEnabled = useIsPWAEnabled();
  const { user } = useAuth();

  return (
    <Head>
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
      {stonlyTourActive && <script async src="/scripts/stonly.js" />}
      {isPWAEnabled && <link rel="manifest" href="/static/site.webmanifest" />}
      {user && user?.user_type_code === 'PATIENT' && (
        <>
          <script
            src="https://eu-west-1-prod-webchat.cxengage.net/load-chat.js"
            data-cxengage-web-integration-id="6788efa94d3151cbe48894d1"
            data-cxengage-tenant-id="919091b5-b41d-44f0-aa7a-2f49bda89de4"
            data-cxengage-region="eu-1"
          />
          <script src="https://us-east-1-prod-webchat.cxengage.net/init-chat.js" />
        </>
      )}
    </Head>
  );
};

// eslint-disable-next-line space-before-function-paren
const HBSApp = ({ Component, pageProps }) => {
  // todo - this should use useRef()?
  let idleTimer = null;
  const router = useRouter();
  const [queryClient] = useState(() => new QueryClient({ defaultOptions: { queries: { staleTime: 100 * 1000 } } }));
  const mounted = useMounted();
  const [contextValue, setContextValue] = useState(null);
  const [amplifyConfigured, setAmplifyConfigured] = useState(false);
  const { store, storeReady } = useStoreAndMaybeRedirect(amplifyConfigured, router);
  const [googleAnalyticsId, setGoogleAnalyticsId] = useState('');
  const [performanceCookiesAccepted, setPerformanceCookiesAccepted] = useState(false);
  const BASEPATH = process.env.BASEPATH || '';
  const [i18nReady, setI18nReady] = useState(false);
  const { isReady } = useHarReady();

  const urlParams =
    typeof window !== 'undefined' ? Object.fromEntries(new URLSearchParams(window?.location?.search).entries()) : {};
  const { error_description, source_url } = urlParams;

  useEffect(() => {
    initialise_i18n(BASEPATH).then(() => setI18nReady(true));
  }, [BASEPATH]);

  useEffect(() => {
    // since configureAmplify potentially overwrites localStorage(!), wait until Amplify is configured before
    // trying to set the performance cookies state variable.
    if (amplifyConfigured && localStorage) {
      setPerformanceCookiesAccepted(
        localStorage.getItem('cookie-consent') === 'all' || localStorage.getItem('cookie-consent') === 'per'
      );
    }
  }, [amplifyConfigured]);

  const stonlyTourActive =
    !Auth.authenticatedUser && publicRuntimeConfig.TOUR_PROVIDER === 'STONLY' && performanceCookiesAccepted === true;

  const checkForHighContrast = () => {
    if (typeof window === 'undefined' || typeof sessionStorage === 'undefined') return;

    if (sessionStorage.getItem('contrast') === 'high') document.body.setAttribute('data-contrast', 'high');
    if (sessionStorage.getItem('contrast') === 'normal') document.body.setAttribute('data-contrast', 'normal');
  };

  checkForHighContrast();

  useEffect(() => {
    getDynamicConfig().then(async ({ config, response }) => {
      if (response.ok) {
        Object.assign(publicRuntimeConfig, getUpdatedConfig(config));

        if (Auth.authenticatedUser) {
          delete publicRuntimeConfig.TOUR_PROVIDER;
        }

        // @ts-ignore
        window.process ||= {
          env: { ...publicRuntimeConfig, REACT_APP_AWS_ENDPOINT_NAME: publicRuntimeConfig.AWS_ENDPOINT_NAME }
        };
        window.checkExpireSession = () => true;
        configureAmplify(sessionStorage, publicRuntimeConfig.LUCY_ENV);

        // this if statement is for the AXA SSO forgot password redirect
        // has to happen before later variables which might redirect to another page
        // and prevent the forgot password flow from working
        if (error_description && publicRuntimeConfig.IDENTITY_PROVIDERS?.providers) {
          const code = `-ERR-${error_description.split(':')[0]}`;
          const handlerProvider = publicRuntimeConfig.IDENTITY_PROVIDERS?.providers.find((p) =>
            p.provider.includes(code)
          );
          if (handlerProvider) {
            Auth.federatedSignIn({ provider: handlerProvider.provider });
            return;
          }
          const { search, origin, pathname } = window.location;
          const searchParams = new URLSearchParams(search);

          searchParams.delete('error_description');
          window.history.pushState({}, '', `${origin}${pathname}?${searchParams.toString()}`);
        }
        setAmplifyConfigured(true);
        setContextValue(publicRuntimeConfig);
        if (!Auth.authenticatedUser) {
          // @ts-ignore the Config property names have not been uppercased yet to match the TypeScript type declaration
          setGoogleAnalyticsId(config.GOOGLE_ANALYTICS_TAG || config.google_analytics_tag);
        }
        // const client_translations = await fetch(`${BASEPATH}/locales/${config.theme.toLowerCase()}/en/common.json`);
        // if (client_translations.ok) {
        //   i18n.addResources('en', 'common', await client_translations.json());
        // }
      } else {
        window.location.replace('/errors/404/index.html');
      }
    });
  }, [BASEPATH, error_description]);

  useEffect(() => {
    if (
      googleAnalyticsId &&
      sessionStorage.getItem('ga-configured') !== 'true' &&
      performanceCookiesAccepted &&
      !Auth.authenticatedUser
    ) {
      const options: { campaign_source?: string; campaign_id?: string } = {};
      if (source_url) options.campaign_source = source_url;
      if (sessionStorage.getItem('campaign_id')) options.campaign_id = sessionStorage.getItem('campaign_id') || '';

      ReactGA.initialize(googleAnalyticsId, {
        gaOptions: options
      });
      sessionStorage.setItem('ga-configured', 'true');
    }
  }, [googleAnalyticsId, performanceCookiesAccepted]);

  const onIdle = async () => {
    if (Auth.authenticatedUser) return;
    if (idleTimer.isIdle()) {
      await Auth.signOut({ global: true });
      await router.push('/login');
      showLogoutAlert(`You were logged out due to session timeout (currently on ${window.location.href})`);
    } else {
      idleTimer.reset();
    }
  };

  const cookiePreferencesUpdated = () => {
    if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
      const cookiePrefs = localStorage.getItem('cookie-consent');
      setPerformanceCookiesAccepted(cookiePrefs ? cookiePrefs === 'all' || cookiePrefs.includes('per') : false);
    }
  };

  if (!mounted() || !i18nReady || error_description) return null;

  return (
    <NotificationProvider>
      <CancelledAppointmentsProvider>
        <QueryClientProvider client={queryClient}>
          <ThemeContextWrapper>
            {/* @ts-ignore */}
            <Provider store={store}>
              <ConfigContext.Provider value={contextValue}>
                <HTMLHead stonlyTourActive={stonlyTourActive} />
                <></>
                <IdleTimer
                  ref={(ref) => {
                    idleTimer = ref;
                  }}
                  onIdle={onIdle}
                  startOnMount
                  debounce={250}
                  timeout={1000 * 60 * 60}
                />
                {!storeReady && <p>Initialising Redux store</p>}
                {!contextValue && <p>Loading runtime configuration</p>}
                <div>
                  <DismissibleToast />
                  {storeReady && contextValue && (
                    <>
                      <AppointmentContextWrapper>
                        {isReady && (
                          <WrappedHBSApp Component={Component} queryClient={queryClient} componentProps={pageProps} />
                        )}
                        <WrappedFooter />
                      </AppointmentContextWrapper>
                      {!Auth.isInHARMode && (
                        <CookieContainer
                          cookiePreferencesUpdated={cookiePreferencesUpdated}
                          tourActive={stonlyTourActive}
                        />
                      )}
                      {Auth.isInHARMode && isReady && <MaybeLoadUser />}
                    </>
                  )}
                </div>
              </ConfigContext.Provider>
            </Provider>
            <DynamicStylesheet />
          </ThemeContextWrapper>
          <div data-chromatic="ignore">
            <ReactQueryDevtools initialIsOpen={false} />
          </div>
        </QueryClientProvider>
      </CancelledAppointmentsProvider>
    </NotificationProvider>
  );
};

const WrappedFooter = () => {
  const { user } = useAuth();
  return (
    <>
      <Footer />
      {user?.self_reg_required === false && !!user?.policies_accepted && <AppointmentWait />}
    </>
  );
};

const Top = (props: any) => {
  const router = useRouter();
  const match = router.asPath.match(/[^?]+/);
  const pathname = match ? match[0] : router.asPath;

  const location = useMemo(
    () =>
      typeof window !== 'undefined'
        ? window.location
        : {
            search: router?.asPath.replace(/[^?]+/u, '')
          },
    [router?.asPath]
  );

  const history = useMemo(
    () => ({
      push: ({ search }) =>
        router?.push({ pathname: router.pathname, query: router.query, search }, undefined, { scroll: false }),
      replace: ({ search }) => {
        router?.replace({ pathname: router.pathname, query: router.query, search }, undefined, { scroll: false });
      },
      location
    }),
    [location, router, pathname]
  );

  if (typeof window === 'undefined') return null;

  return (
    <QueryParamProvider history={history} location={location}>
      <HarReadyProvider>
        <HBSApp {...props} />
      </HarReadyProvider>
    </QueryParamProvider>
  );
};

export default Top;
