import * as actionTypes from '@actionTypes';
import { fetchData } from './action';

interface PreviousSurvey {
  max_surveyjs_def_id: string;
  survey_json: string;
  response_json: string;
  survey_code: string;
}

interface PreviousSurveys extends Array<PreviousSurvey> {
  previous_surveys: PreviousSurvey[];
}

interface SurveyElement {
  type: string;
  name: string;
  QuestionName?: string;
  SurveyCode?: string;
  [p: string]: string | undefined;
}

interface SurveyPage {
  name: string;
  elements: SurveyElement[];
}

interface Survey {
  pages: SurveyPage[];
}

interface PreviousQuestion {
  type?: string;
  name: string;
  visible?: boolean;
  SurveyCode?: string;
  QuestionName: string;
}

interface PreviousQuestions extends Array<PreviousQuestion> {}

interface PostSurveyParams {
  user_guid: string;
  surveyjs_id: number;
  complete: boolean;
  response_json: string;
  generated_def?: string;
}

interface GetSurveyParams {
  surveyjs_id?: number;
  response?: number;
  appt_id?: string;
  case_id?: string;
  gh?: boolean;
}

export const getSurveyJsId = (surveyjs_id?: number) => {
  const requestOption = {
    params: `surveys/surveyjs/APPUSER?surveyjs_id=${surveyjs_id}`
  };
  return fetchData(requestOption);
};

export const getSurveyJsDefinitionId = (surveyjs_def_id?: number) => {
  const requestOption = {
    params: `admin/surveyjs/def/APPUSER?surveyjs_def_id=${surveyjs_def_id}`
  };
  return fetchData(requestOption);
};

export const getSurveyJsDefinition = (params: { cat_code: string; event_code: string; current?: string }) => {
  const requestOption = {
    params: `admin/surveyjs/def/APPUSER?${new URLSearchParams(params).toString()}`
  };
  return fetchData(requestOption);
};

export const getAssessmentSurveyDefinition = (surveyjs_def_id: number, surveyjs_id?: number) => {
  const requestOption = {
    params: `admin/surveyjs/def/APPUSER?surveyjs_def_id=${surveyjs_def_id}`
  };
  if (surveyjs_id) {
    requestOption.params = `admin/surveyjs/def/APPUSER?surveyjs_def_id=${surveyjs_def_id}&surveyjs_id=${surveyjs_id}`;
  }
  return fetchData(requestOption);
};

export const postAssessmentSurvey = (request: PostSurveyParams) => {
  const requestOption = {
    params: 'surveys/surveyjs',
    method: 'post',
    postBody: request
  };
  return fetchData(requestOption);
};

export const getAssessmentSurvey = (request: GetSurveyParams) => {
  const requestOption = {
    params: `surveys/surveyjs/APPUSER?${new URLSearchParams(Object.entries(request)).toString()}`
  };
  return fetchData(requestOption);
};

export const getAssessmentSurveys = (request: GetSurveyParams) => {
  const requestOption = {
    params: `surveys/surveyjs/APPUSER?${new URLSearchParams(Object.entries(request)).toString()}`,
    actionType: actionTypes.ASSESSMENT_SURVEY_SUCCESS,
    errorType: actionTypes.ASSESSMENT_SURVEY_FAIL
  };
  return fetchData(requestOption);
};

export const getPreviousQuestions = (
  surveyCodes: string[],
  user_guid: string,
  survey_id: number,
  case_id?: number | null
) => {
  const request = {
    user_guid,
    previous_types: surveyCodes,
    survey_id,
    case_id
  };
  const requestOption = {
    params: 'surveys/surveyjs/previous',
    method: 'post',
    postBody: request
  };
  return fetchData(requestOption);
};

const getPreviousAnswer = (answers: string, referencedQuestionName: string) => {
  const prevSurveyAnswers = JSON.parse(answers);
  return prevSurveyAnswers[referencedQuestionName];
};

const postNewSurveyDefinitionAndAnswers = async (
  newAnswersResponse: string,
  surveyJSON: string,
  surveyjs_id: number,
  user_guid: string,
  dispatch: (thunk: any) => any
) => {
  const postSurveyDetails = {
    user_guid,
    surveyjs_id,
    complete: false,
    response_json: newAnswersResponse,
    generated_def: surveyJSON
  };
  await dispatch(postAssessmentSurvey(postSurveyDetails));
};

const findPreviousQuestions = (surveyJSON: Survey) => {
  return surveyJSON.pages.map((page) => page.elements.filter((e) => e.type === 'prevquestion')).flat();
};

const getQuestionReplacements = (
  questionsToFind: PreviousQuestions,
  previousQuestionArray: PreviousSurvey[],
  getAnswers: boolean
) => {
  const previousSurveyMap = new Map(
    previousQuestionArray.map((previousSurvey) => [previousSurvey.survey_code, previousSurvey])
  );

  return questionsToFind
    .map((previousQuestion) => {
      const previousSurveyData: PreviousSurvey = previousSurveyMap.get(previousQuestion.SurveyCode);
      const currentSurveyDefinition = JSON.parse(previousSurveyData.survey_json);
      const previousElement = currentSurveyDefinition.pages
        .map(({ elements }) => elements)
        .flat()
        .find(({ name }) => {
          const currentQuestionMatchesPreviousQuestion = name === previousQuestion.QuestionName;
          return currentQuestionMatchesPreviousQuestion;
        });

      const questionHasAnAnswer = previousElement && getAnswers && previousSurveyData.response_json;
      if (questionHasAnAnswer) {
        const previousAnswer = getPreviousAnswer(previousSurveyData.response_json, previousQuestion.QuestionName);
        return {
          previousElement,
          previousAnswer,
          name: previousQuestion.name,
          previousName: previousQuestion.QuestionName,
          latestSurvey: previousSurveyData.max_surveyjs_def_id
        };
      }

      return {
        previousElement,
        previousAnswer: null,
        name: previousQuestion.name,
        previousName: previousQuestion.QuestionName,
        latestSurvey: previousSurveyData.max_surveyjs_def_id
      };
    })
    .flat();
};

export const getExpandedAssessmentSurveyDef = (
  surveyjs_def_id: number,
  surveyjs_id: number,
  user_guid: string,
  pageType: 'clinician' | 'patients',
  case_id?: number | null
) => {
  return async (dispatch: (thunk: any) => any) => {
    const surveyDefinitionResult = await dispatch(getAssessmentSurveyDefinition(surveyjs_def_id, surveyjs_id));
    const surveyResponse = await dispatch(getAssessmentSurvey({ surveyjs_id }));

    const isProbablySafeToPost =
      pageType === 'patients' || (pageType === 'clinician' && surveyDefinitionResult.clin_editable);

    if (surveyResponse.user_surveyjs[0].definition_pregenerated === 0 && isProbablySafeToPost) {
      const surveyJSON: Survey = JSON.parse(surveyDefinitionResult.surveyjs_defs[0].survey_json);
      const previousQuestions = findPreviousQuestions(surveyJSON) as PreviousQuestions;

      const surveyHasPreviousQuestions = previousQuestions.length > 0;
      if (surveyHasPreviousQuestions) {
        const previousSurveys: PreviousSurveys = await dispatch(
          getPreviousQuestions(
            previousQuestions.map(({ SurveyCode }) => SurveyCode),
            user_guid,
            surveyjs_id,
            case_id
          )
        );
        const questionAndAnswerReplacements = getQuestionReplacements(
          previousQuestions,
          previousSurveys.previous_surveys,
          true
        );

        const foundPreviousQuestionsAndAnswers = questionAndAnswerReplacements.length > 0;
        if (foundPreviousQuestionsAndAnswers) {
          const checkedReplacements = questionAndAnswerReplacements.filter(
            (replacement) => replacement.previousElement
          );

          const replacementMap = new Map(
            checkedReplacements.map(({ previousElement }) => [previousElement.name, previousElement])
          );
          const mergePreviousQuestion = (e, replacementMap) => {
            // eslint-disable-next-line no-unused-vars
            const previousProperties = replacementMap.get(e.QuestionName);
            if (previousProperties?.visibleIf) delete previousProperties.visibleIf;
            // eslint-disable-next-line no-unused-vars
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { type: __, SurveyCode: ___, QuestionName: ____, ...overriddenProperties } = e;

            return { ...previousProperties, ...overriddenProperties };
          };

          surveyJSON.pages.forEach(
            (page) =>
              (page.elements = page.elements.map((e) =>
                e.type === 'prevquestion' ? mergePreviousQuestion(e, replacementMap) : e
              ))
          );

          const newAnswers = {};
          questionAndAnswerReplacements.forEach(({ previousAnswer, name }) => {
            if (previousAnswer) {
              newAnswers[name] = previousAnswer;
            }
          });

          const newAnswersResponse = JSON.stringify(newAnswers);
          await postNewSurveyDefinitionAndAnswers(
            newAnswersResponse,
            JSON.stringify(surveyJSON),
            surveyjs_id,
            user_guid,
            dispatch
          );
        }
      }

      return {
        surveyDefinition: await dispatch(getAssessmentSurveyDefinition(surveyjs_def_id, surveyjs_id)),
        surveyResponse: await dispatch(getAssessmentSurvey({ surveyjs_id }))
      };
    }
    return { surveyDefinition: surveyDefinitionResult, surveyResponse: null };
  };
};
