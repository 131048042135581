export const AUTH_USER_SET_ROLE = 'AUTH_USER_SET_ROLE';

export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';

export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_FAIL = 'AUTH_LOGIN_FAIL';
export const AUTH_SESSION_EXPIRED = 'AUTH_SESSION_EXPIRED';
export const AUTH_LOGIN_CODE = 'AUTH_LOGIN_CODE';
export const AUTH_LOGIN_NEWPASSOWRD = 'AUTH_LOGIN_NEWPASSOWRD';
export const AUTH_SIGNED_OUT = 'AUTH_SIGNED_OUT';
export const AUTH_SIGNED_OUT_SUCCESS = 'AUTH_SIGNED_OUT_SUCCESS';

export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';
export const AUTH_LOGOUT_FAIL = 'AUTH_LOGOUT_FAIL';

export const AUTH_LOGIN_RESETPASSWORD_FAIL = 'AUTH_LOGIN_RESETPASSWORD_FAIL';
export const AUTH_LOGIN_RESETPASSWORD_SUCCESS = 'AUTH_LOGIN_RESETPASSWORD_SUCCESS';

export const AUTH_LOGIN_NEWPASSWORD_FAIL = 'AUTH_LOGIN_NEWPASSWORD_FAIL';
export const AUTH_LOGIN_NEWPASSWORD_SUCCESS = 'AUTH_LOGIN_NEWPASSWORD_SUCCESS';

export const AUTH_LOGIN_RESETPASSWORD_CONFIRM_SUCCESS = 'AUTH_LOGIN_RESETPASSWORD_CONFIRM_SUCCESS';
export const AUTH_LOGIN_RESETPASSWORD_CONFIRM_FAIL = 'AUTH_LOGIN_RESETPASSWORD_CONFIRM_FAIL';

export const USER_APPOINTMENTS_SUCCESS = 'USER_APPOINTMENTS_SUCCESS';
export const USER_APPOINTMENTS_FAIL = 'USER_APPOINTMENTS_FAIL';

export const USER_HISTORY_APPOINTMENT_FAIL = 'USER_HISTORY_APPOINTMENT_FAIL';
export const USER_HISTORY_APPOINTMENT_SUCCESS = 'USER_HISTORY_APPOINTMENT_SUCCESS';

export const USER_DIARY_FAIL = 'USER_DIARY_FAIL';
export const USER_DIARY_SUCCESS = 'USER_DIARY_SUCCESS';

export const USER_NEXT_APPOINTMENT_SUCCESS = 'USER_NEXT_APPOINTMENT_SUCCESS';
export const USER_NEXT_APPOINTMENT_FAIL = 'USER_NEXT_APPOINTMENT_FAIL';

export const USER_TIMELINEAPPOINTMENTHISTORY_FAIL = 'USER_TIMELINEAPPOINTMENTHISTORY_FAIL';
export const USER_TIMELINEAPPOINTMENTHISTORY_SUCCESS = 'USER_TIMELINEAPPOINTMENTHISTORY_SUCCESS';

export const USER_UPCOMINGHISTORY_FAIL = 'USER_UPCOMINGHISTORY_FAIL';
export const USER_UPCOMINGHISTORY_SUCCESS = 'USER_UPCOMINGHISTORY_SUCCESS';

export const UPLOAD_DOCS_DATABASE_FAIL = 'UPLOAD_DOCS_DATABASE_FAIL';
export const UPLOAD_DOCS_DATABASE_SUCCESS = 'UPLOAD_DOCS_DATABASE_SUCCESS';

export const UPLOAD_DOCS_FAIL = 'UPLOAD_DOCS_FAIL';
export const UPLOAD_DOCS_SUCCESS = 'UPLOAD_DOCS_SUCCESS';

export const USER_POLICY_FAIL = 'USER_POLICY_FAIL';
export const USER_POLICY_SUCCESS = 'USER_POLICY_SUCCESS';
export const USER_POLICY_ACCEPTED_FAIL = 'USER_POLICY_ACCEPTED_FAIL';
export const USER_POLICY_ACCEPTED_SUCCESS = 'USER_POLICY_ACCEPTED_SUCCESS';

export const LOG_ROCKET_INITIALISED = 'LOG_ROCKET_INITIALISED';

export const ALL_SURVEYS_SUCCESS = 'ALL_SURVEYS_SUCCESS';
export const ALL_SURVEYS_FAIL = 'ALL_SURVEY_FAIL';

export const RESCHEDULE_CONFIRM_SUCCESS = 'RESCHEDULE_CONFIRM_SUCCESS';
export const RESCHEDULE_CONFIRM_FAIL = 'RESCHEDULE_CONFIRM_FAIL';

export const RESCHEDULE_CALENDAR_FAIL = 'RESCHEDULE_CALENDAR_FAIL';
export const RESCHEDULE_CALENDAR_SUCCESS = 'RESCHEDULE_CALENDAR_SUCCESS';
export const RESCHEDULE_CANCEL_FAIL = 'RESCHEDULE_CANCEL_FAIL';
export const RESCHEDULE_CANCEL_SUCCESS = 'RESCHEDULE_CANCEL_SUCCESS';
export const RESCHEDULE_CANCELMETA_FAIL = 'RESCHEDULE_CANCELMETA_FAIL';
export const RESCHEDULE_CANCELMETA_SUCCESS = 'RESCHEDULE_CANCELMETA_SUCCESS';
export const RESCHEDULE_CANCELAPPOINTMENT_TASK_SUCCESS = 'RESCHEDULE_CANCELAPPOINTMENT_TASK_SUCCESS';
export const RESCHEDULE_CANCELAPPOINTMENT_TASK_FAIL = 'RESCHEDULE_CANCELAPPOINTMENT_TASK_FAIL';

export const ASSESSMENT_SURVEY_SUCCESS = 'ASSESSMENT_SURVEY_SUCCESS';
export const ASSESSMENT_SURVEY_FAIL = 'ASSESSMENT_SURVEY_FAIL';

export const CLINICIANS_REQUEST_START = 'CLINICIANS_REQUEST_START';
export const CLINICIANS_REQUEST_FAIL = 'CLINICIANS_REQUEST_FAIL';

export const PATIENTS_REQUEST_START = 'PATIENTS_REQUEST_START';
export const PATIENTS_REQUEST_FAIL = 'PATIENTS_REQUEST_FAIL';

export const APPOINTMENT_WEBCAM_STATE = 'WEBCAM_STATE';
export const APPOINTMENT_THEIR_STATUS = 'WEBCAM_THEIR_STATUS';

export const PARTICIPANT_ADD_MESSAGE = 'PARTICIPANT_ADD_MESSAGE';
export const PARTICIPANT_DISPLAY_LAST_MESSAGE = 'PARTICIPANT_DISPLAY_LAST_MESSAGE';
export const PARTICIPANT_HIDE_MESSAGE = 'PARTICIPANT_HIDE_MESSAGE';
export const PARTICIPANT_ENQUEUE_OUTGOING_MESSAGE = 'PARTICIPANT_ENQUEUE_OUTGOING_MESSAGE';
export const PARTICIPANT_DISCARD_OUTGOING_MESSAGE = 'PARTICIPANT_DISCARD_OUTGOING_MESSAGE';

export const CONFIRM_EMAIL_CHANGE_SUCCESS = 'CONFIRM_EMAIL_CHANGE_SUCCESS';
export const CONFIRM_EMAIL_CHANGE_FAIL = 'CONFIRM_EMAIL_CHANGE_FAIL';

export const UPDATE_DETAILS_SUCCESS = 'UPDATE_DETAILS_SUCCESS';
export const UPDATE_DETAILS_FAIL = 'UPDATE_DETAILS_FAIL';

// Patients Satisfaction Survey
export const PATIENTS_SATISFACTION_SURVEY_QUESTION_SUCCESS = 'PATIENTS_SATISFACTION_SURVEY_QUESTION_SUCCESS';
export const PATIENTS_SATISFACTION_SURVEY_QUESTION_FAIL = 'PATIENTS_SATISFACTION_SURVEY_QUESTION_FAIL';
export const PATIENTS_SATISFACTION_SURVEY_SUCCESS = 'PATIENTS_SATISFACTION_SURVEY_SUCCESS';
export const PATIENTS_SATISFACTION_SURVEY_FAIL = 'PATIENTS_SATISFACTION_SURVEY_FAIL';

// Patients FAQ
export const PATIENTS_FAQ_SUCCESS = 'PATIENTS_FAQ_SUCCESS';
export const PATIENTS_FAQ_FAIL = 'PATIENTS_FAQ_FAIL';

// Patients Pre Assessment
export const PATIENTS_PREASSETMENTS_FAIL = 'PATIENTS_PREASSETMENTS_FAIL';
export const PATIENTS_PREASSETMENTS_SUCCESS = 'PATIENTS_PREASSETMENTS_SUCCESS';

// Patients Pre Assessment Question
export const PATIENTS_PREASSETMENTS_QUESTIONS_FAIL = 'PATIENTS_PREASSETMENTS_QUESTIONS_FAIL';
export const PATIENTS_PREASSETMENTS_QUESTIONS_SUCCESS = 'PATIENTS_PREASSETMENTS_QUESTIONS_SUCCESS';

// Patients Profile
export const PATIENTS_PROFILE_FAIL = 'PATIENTS_PROFILE_FAIL';
export const PATIENTS_PROFILE_SUCCESS = 'PATIENTS_PROFILE_SUCCESS';

// Patients Choose Clinician
export const PATIENTS_ALLCLINICIAN_FAIL = 'PATIENTS_ALLCLINICIAN_FAIL';
export const PATIENTS_ALLCLINICIAN_SUCCESS = 'PATIENTS_ALLCLINICIAN_SUCCESS';

// Patients Timeline Case
export const PATIENTS_TIMELINECASE_FAIL = 'PATIENTS_TIMELINECASE_FAIL';
export const PATIENTS_TIMELINECASE_SUCCESS = 'PATIENTS_TIMELINECASE_SUCCESS';

// Patients Discharge
export const PATIENTS_DISCHARGE_FAIL = 'PATIENTS_DISCHARGE_FAIL';
export const PATIENTS_DISCHARGE_SUCCESS = 'PATIENTS_DISCHARGE_SUCCESS';

// Clinician Satisfaction
export const CLINICIANS_SATISFACTION_SCORE_FAIL = 'CLINICIANS_SATISFACTION_SCORE_FAIL';
export const CLINICIANS_SATISFACTION_SCORE_SUCCESS = 'CLINICIANS_SATISFACTION_SCORE_SUCCESS';

export const CLINICIANS_APPOINTMENT_ACTIVE_SUCCESS = 'CLINICIANS_APPOINTMENT_ACTIVE_SUCCESS';
export const CLINICIANS_APPOINTMENT_ACTIVE_FAIL = 'CLINICIANS_APPOINTMENT_ACTIVE_FAIL';

export const CLINICIANS_APPOINTMENT_COMPLETE_SUCCESS = 'CLINICIANS_APPOINTMENT_COMPLETE_SUCCESS';
export const CLINICIANS_APPOINTMENT_COMPLETE_FAIL = 'CLINICIANS_APPOINTMENT_COMPLETE_FAIL';

export const CLINICIANS_NOTE_SUCCESS = 'CLINICIANS_NOTE_SUCCESS';
export const CLINICIANS_NOTE_FAIL = 'CLINICIANS_NOTE_FAIL';

export const CLINICIANS_GP_RECORD_SUCCESS = 'CLINICIANS_GP_RECORD_SUCCESS';
export const CLINICIANS_GP_RECORD_FAIL = 'CLINICIANS_GP_RECORD_FAIL';

export const CLINICIANS_TIMELINECASE_FAIL = 'CLINICIANS_TIMELINECASE_FAIL';
export const CLINICIANS_TIMELINECASE_SUCCESS = 'CLINICIANS_TIMELINECASE_SUCCESS';

export const CLINICIANS_GENERAL_QUESTIONS_SUCCESS = 'CLINICIANS_GENERAL_QUESTIONS_SUCCESS';
export const CLINICIANS_GENERAL_QUESTIONS_FAIL = 'CLINICIANS_GENERAL_QUESTIONS_FAIL';

export const CLINICIANS_COMPLETED_TASKS_SUCCESS = 'CLINICIANS_COMPLETED_TASKS_SUCCESS';
export const CLINICIANS_COMPLETED_TASKS_FAIL = 'CLINICIANS_COMPLETED_TASKS_FAIL';

export const CLINICIANS_OPEN_TASKS_SUCCESS = 'CLINICIANS_OPEN_TASKS_SUCCESS';
export const CLINICIANS_OPEN_TASKS_FAIL = 'CLINICIANS_OPEN_TASKS_FAIL';

export const CLINICIANS_CASE_NEW_SUCCESS = 'CLINICIANS_CASE_NEW_SUCCESS';
export const CLINICIANS_CASE_NEW_FAIL = 'CLINICIANS_CASE_NEW_FAIL';

export const CLINICIANS_CASE_ACTIVE_SUCCESS = 'CLINICIANS_CASE_ACTIVE_SUCCESS';
export const CLINICIANS_CASE_ACTIVE_FAIL = 'CLINICIANS_CASE_ACTIVE_FAIL';

export const CLINICIANS_CASE_CLOSED_SUCCESS = 'CLINICIANS_CASE_CLOSED_SUCCESS';
export const CLINICIANS_CASE_CLOSED_FAIL = 'CLINICIANS_CASE_CLOSED_FAIL';

export const DATA_PROTECTION_SUCCESS = 'DATA_PROTECTION_SUCCESS';
export const DATA_PROTECTION_FAIL = 'DATA_PROTECTION_FAIL';

export const POST_DATA_PROTECTION_SUCCESS = 'POST_DATA_PROTECTION_SUCCESS';
export const POST_DATA_PROTECTION_FAIL = 'POST_DATA_PROTECTION_FAIL';

export const LAMDA_PDF_SUCCESS = 'LAMDA_PDF_SUCCESS';
export const LAMDA_PDF_FAIL = 'LAMDA_PDF_FAIL';

export const VOICE_TOKEN_SUCCESS = 'VOICE_TOKEN_SUCCESS';
export const VOICE_TOKEN_FAIL = 'VOICE_TOKEN_FAIL';

export const PATIENT_ASSESSMENTS_SUCCESS = 'PATIENT_ASSESSMENTS_SUCCESS';
export const PATIENT_ASSESSMENTS_FAIL = 'PATIENT_ASSESSMENTS_FAIL';

export const LETTER_FILES_SUCCESS = 'LETTER_FILES_SUCCESS';
export const LETTER_FILES_FAIL = 'LETTER_FILES_FAIL';

export const CREATE_TASK_SUCCESS = 'CREATE_TASK_SUCCESS';
export const CREATE_TASK_FAIL = 'CREATE_TASK_FAIL';

export const CLINICIANS_SAVE_APPOINTMENT_OUTCOME_SUCCESS = 'CLINICIANS_SAVE_APPOINTMENT_OUTCOME_SUCCESS';
export const CLINICIANS_SAVE_APPOINTMENT_OUTCOME_FAIL = 'CLINICIANS_SAVE_APPOINTMENT_OUTCOME_FAIL';

export const GET_DOCS_SUCCESS = 'GET_DOCS_SUCCESS';
export const GET_DOCS_FAIL = 'GET_DOCS_FAIL';

export const ACTION_SUCCESS = 'ACTION_SUCCESS';
export const ACTION_FAIL = 'ACTION_FAIL';

// Clinician investigation request
export const GET_PDF_FORM_SUCCESS = 'GET_PDF_FORM_SUCCESS';
export const GET_PDF_FORM_FAIL = 'GET_PDF_FORM_FAIL';

export const SET_CASE_PATHWAY_SUCCESS = 'SET_CASE_PATHWAY_SUCCESS';
export const SET_CASE_PATHWAY_FAIL = 'SET_CASE_PATHWAY_FAIL';
