import Axios from 'axios';
import getConfig from 'next/config';
import formatDate from 'vl-common/src/lib/formatDate';
import { UsersMetaSchema } from 'vl-common/src/schemas/getMetaUsers';
import { MiscMetaSchema } from 'vl-common/src/schemas/getMetaMisc';
import { z } from 'zod';
import { TodoSchema } from 'vl-common/src/schemas/shared/todo';
import { ClinicianSchema } from 'vl-common/src/schemas/getCliniciansClinicians';
import * as actionTypes from './actionTypes';
import { fetchData } from './action';

const { publicRuntimeConfig } = getConfig() || {};

export const requestStart = () => ({
  type: actionTypes.PATIENTS_REQUEST_START
});

export const requestFail = (error) => ({
  type: actionTypes.PATIENTS_REQUEST_FAIL,
  error
});

/* getPreAssetments */
export const getPreAssetments = () => {
  const requestOption = {
    params: 'users/preass/APPUSER?hier_code=PREASS1',
    actionType: actionTypes.PATIENTS_PREASSETMENTS_SUCCESS,
    errorType: actionTypes.PATIENTS_PREASSETMENTS_FAIL
  };
  return fetchData(requestOption);
};

/* getPreAssetments Question */
export const preAssetmentsQuestion = (request) => {
  const requestOption = {
    params: 'users/preass',
    method: 'POST',
    postBody: request,
    actionType: actionTypes.PATIENTS_PREASSETMENTS_QUESTIONS_SUCCESS,
    errorType: actionTypes.PATIENTS_PREASSETMENTS_QUESTIONS_FAIL
  };
  return fetchData(requestOption);
};

/* Satisfaction Survey */
export const satisfactionSurvey = (request) => {
  const requestOption = request
    ? {
        params: 'users/satis/',
        method: 'POST',
        postBody: request,
        actionType: actionTypes.PATIENTS_SATISFACTION_SURVEY_QUESTION_SUCCESS,
        errorType: actionTypes.PATIENTS_SATISFACTION_SURVEY_QUESTION_FAIL
      }
    : {
        params: 'users/satis/APPUSER?rows=2',
        actionType: actionTypes.PATIENTS_SATISFACTION_SURVEY_SUCCESS,
        errorType: actionTypes.PATIENTS_SATISFACTION_SURVEY_FAIL
      };
  return fetchData(requestOption);
};

/* Confirm Email Change */
export const confirmEmailChange = (token) => {
  const options = {
    params: 'confirm-change',
    postBody: { token },
    method: 'POST',
    nobearer: true,
    actionType: actionTypes.CONFIRM_EMAIL_CHANGE_SUCCESS,
    errorType: actionTypes.CONFIRM_EMAIL_CHANGE_FAIL
  };
  return fetchData(options);
};

/* Confirm GDPR */
export const confirmGDPR = (token) => {
  const options = {
    params: 'confirm-gdpr',
    postBody: { token },
    nobearer: true,
    method: 'POST'
  };
  return fetchData(options);
};

/* Confirm GDPR */
export const sendGDPREmail = (patient_guid, appointment_id) => {
  const options = {
    params: 'gdpr/request',
    postBody: {
      user_guid: patient_guid,
      appt_id: appointment_id,
      process_code: 'PATIENT_REFERRAL',
      reason_code: 'REQ_CLINICIAN'
    },
    method: 'PUT'
  };
  return fetchData(options);
};

/* Outcome Measures */
export const outcomeMeasures = () => {
  const options = {
    params: 'surveys/proms/APPUSER?rows=2'
  };
  return fetchData(options);
};

export const promsQuestions = (postBody) => {
  const options = {
    params: 'surveys/proms',
    method: 'post',
    postBody
  };
  return fetchData(options);
};

export const usersMeta = () => {
  const options = {
    params: 'meta/users/APPUSER'
  };
  return fetchData(options, z.object({ meta: z.array(UsersMetaSchema) }));
};

// Here //

export const getAllCompanies = () => {
  const options = {
    params: 'admin/companies/APPUSER?rows=99&order=asc&sort=name&exclude_hbs=true'
  };
  return fetchData(options);
};

export const metaMisc = () => {
  const options = {
    params: 'meta/misc/APPUSER'
  };
  return fetchData(options, z.object({ meta: z.array(MiscMetaSchema) }));
};

export const supportInfo = () => {
  const options = {
    params: 'meta/support/APPUSER'
  };
  return fetchData(options);
};

export const updateDetails = (postBody: {
  user_guid?: string;
  title?: string;
  first_name?: string;
  last_name?: string;
  tel_mobile?: string;
  tel_other?: string;
  tel_pref?: string;
  sex?: string;
  dob?: string | Date;
  mem_no?: string;
  addline1?: string;
  addline2?: string;
  addline3?: string;
  addline4?: string;
  region?: string;
  postcode?: string;
  country?: string;
  gp_title?: string;
  gp_first_name?: string;
  gp_last_name?: string;
  gp_email?: string;
  gp_ods_code?: string;
  gp_virtual?: boolean;
  reg_complete?: boolean;
}) => {
  const options = {
    params: 'users/profile',
    postBody,
    method: 'POST',
    actionType: actionTypes.UPDATE_DETAILS_SUCCESS,
    errorType: actionTypes.UPDATE_DETAILS_FAIL
  };
  return fetchData(
    options,
    z.object({
      up_user_guid: z.string(),
      operation: z.string()
    })
  );
};

export const updateNotificationPref = (postBody) => {
  const options = {
    params: 'outputs/pref',
    postBody,
    method: 'POST'
  };
  return fetchData(options);
};

export const resendCode = (postBody) => {
  const options = {
    params: 'outputs/pref',
    postBody,
    method: 'POST',
    nobearer: true
  };
  return fetchData(options);
};

export const submitCode = (postBody) => {
  const options = {
    params: 'outputs/pref',
    postBody,
    method: 'POST'
  };
  return fetchData(options);
};

export const pushNotificationSubscription = (postBody) => {
  const options = {
    params: 'outputs/push/subscription/APPUSER',
    postBody,
    method: 'PUT'
  };
  return fetchData(options);
};

interface AllClinicianProps {
  clin_guid: string;
  patient_guid: string;
  subtype: string;
  datetime: Date;
  specarea: string;
  spec_cat: string;
  rows: number;
}

export const allClinicians = ({ datetime, ...props }: Partial<AllClinicianProps>) => {
  const formattedDateTime =
    typeof datetime === 'string' ? datetime : formatDate(datetime, 'Date & Time (ISO 1806) - 2022-03-02T17:19:33.173Z');
  const request = datetime ? { datetime: formattedDateTime, ...props } : props;
  const parameters = Object.keys(request)
    .map((key) => `${key}=${request[key]}`)
    .join('&');
  const requestOption = {
    params: `clinicians/clinicians/APPUSER?${parameters}`,
    actionType: actionTypes.PATIENTS_ALLCLINICIAN_SUCCESS,
    errorType: actionTypes.PATIENTS_ALLCLINICIAN_FAIL
  };

  return fetchData(requestOption, z.array(ClinicianSchema));
};

export const face2faceClinicians = (patient_guid, clinicianSpecCat, clinicianAreas, caseId, date, userPostcode) => {
  const requestOption = {
    params: `f2f-consultants/?date=${date}&patient_guid=${patient_guid}&clinicianSpecCat=${clinicianSpecCat}&clinicianAreas=${clinicianAreas}&caseId=${caseId}&userPostcode=${userPostcode}`,
    actionType: actionTypes.PATIENTS_ALLCLINICIAN_SUCCESS,
    errorType: actionTypes.PATIENTS_ALLCLINICIAN_FAIL
  };

  return fetchData(requestOption);
};

export const bookFace2faceClinician = (postBody) => {
  const requestOption = {
    params: 'f2f-consultants/',
    method: 'POST',
    postBody,
    actionType: actionTypes.PATIENTS_ALLCLINICIAN_SUCCESS,
    errorType: actionTypes.PATIENTS_ALLCLINICIAN_FAIL
  };

  return fetchData(requestOption);
};

export const patientsTimelineCase = (id) => {
  const options = {
    params: `cases/APPUSER?patient=${id}`,
    actionType: actionTypes.PATIENTS_TIMELINECASE_SUCCESS,
    errorType: actionTypes.PATIENTS_TIMELINECASE_FAIL
  };
  return fetchData(options);
};

export const getCaseDetails = (id) => {
  const options = {
    params: `cases/APPUSER?case=${id}`
  };
  return fetchData(options);
};

// Active === open
export const getActiveCases = () => {
  const options = {
    params: 'cases/APPUSER?active=true&rows=100'
  };
  return fetchData(options);
};

export const getInactiveCases = () => {
  const options = {
    params: 'cases/APPUSER?active=false&rows=100'
  };
  return fetchData(options);
};

export const getAppointmentMeta = (case_id) => {
  const options = {
    params: `meta/appointments/APPUSER?case_id=${case_id}`
  };
  return fetchData(options);
};

export const fetchGPs = (postcode: string) => {
  const options = {
    params: `providers/practices/APPUSER?geo_postcode=${postcode}`
  };
  return fetchData(
    options,
    z.object({
      practices: z.array(
        z.object({
          ods_code: z.string(),
          practice_name: z.string(),
          email: z.string().nullable(),
          tel_no: z.string(),
          addline1: z.string(),
          addline2: z.string().nullable(),
          addline3: z.string().nullable(),
          addline4: z.string().nullable(),
          region: z.string(),
          postcode: z.string(),
          country: z.string(),
          longitude: z.number(),
          latitude: z.number(),
          nlat: z.number(),
          nlong: z.number(),
          nradius: z.number(),
          distance: z.number(),
          distance_units: z.string()
        })
      )
    })
  );
};

export const fetchAddresses = (postcode: string) => {
  const options = {
    params: `postcode/${postcode}`
  };
  return fetchData(
    options,
    z.object({
      result: z.array(
        z.object({
          postcode: z.string(),
          postcode_inward: z.string(),
          postcode_outward: z.string(),
          post_town: z.string(),
          dependant_locality: z.string(),
          double_dependant_locality: z.string(),
          thoroughfare: z.string(),
          dependant_thoroughfare: z.string(),
          building_number: z.string(),
          building_name: z.string(),
          sub_building_name: z.string(),
          po_box: z.string(),
          department_name: z.string(),
          organisation_name: z.string(),
          udprn: z.number(),
          postcode_type: z.string(),
          su_organisation_indicator: z.string(),
          delivery_point_suffix: z.string(),
          line_1: z.string(),
          line_2: z.string(),
          line_3: z.string(),
          premise: z.string(),
          longitude: z.number(),
          latitude: z.number(),
          eastings: z.number(),
          northings: z.number(),
          country: z.string(),
          traditional_county: z.string(),
          administrative_county: z.string(),
          postal_county: z.string(),
          county: z.string(),
          district: z.string(),
          ward: z.string(),
          uprn: z.string(),
          id: z.string(),
          country_iso: z.string(),
          country_iso_2: z.string(),
          county_code: z.string(),
          language: z.string(),
          umprn: z.string(),
          dataset: z.string()
        })
      ),
      code: z.number(),
      message: z.string(),
      limit: z.number(),
      page: z.number(),
      total: z.number()
    })
  );
};

export const getBoilerplate = (boilerplate_code, case_id) => {
  const options = {
    params: `outputs/boilerplate/APPUSER/${boilerplate_code}/`
  };
  if (case_id) {
    options.params = `outputs/boilerplate/APPUSER/${boilerplate_code}/?p_case_id=${case_id}`;
  }
  return fetchData(options);
};

export const getArticleNo = (category) => {
  const options = {
    params: `outputs/confluence/APPUSER/${category}`
  };
  return fetchData(options);
};

export const fetchArticle = (article) => {
  const options = {
    params: `article/${article}`
  };
  return fetchData(options);
};

export const fetchArticleFromDB = (article) => {
  const options = {
    params: `outputs/template/html?user_guid=APPUSER&template_code=${article}`
  };
  return fetchData(options);
};

export const fetchArticleNoAuth = (article) => {
  const options = {
    nobearer: true,
    params: `article/${article}`
  };
  return fetchData(options);
};

export const fetchArticleNoAuthFromDB = (article) => {
  const options = {
    nobearer: true,
    params: `content/${article}`
  };
  return fetchData(options);
};

// Discharge post method
export const patientsDischarge = (params) => {
  const options = {
    method: 'POST',
    params: 'cases/discharge',
    postBody: params,
    actionType: actionTypes.PATIENTS_DISCHARGE_SUCCESS,
    errorType: actionTypes.PATIENTS_DISCHARGE_FAIL
  };
  return fetchData(options);
};

// Reopen discharged-open case
export const reopenCase = (caseId) => {
  const options = {
    method: 'POST',
    params: 'cases/reopen',
    postBody: caseId
  };
  return fetchData(options);
};

// Get FAQ
export const patientsFaq = () => {
  const options = {
    params: 'users/faq/APPUSER?sort=priority&order=asc&enabled=true', // ! look into this soon
    actionTypes: actionTypes.PATIENTS_FAQ_SUCCESS,
    errorType: actionTypes.PATIENTS_FAQ_FAIL
  };
  return fetchData(options);
};

// Get GP details
export const getGpDetails = (id: string) => {
  const options = {
    params: `users/gp/APPUSER/${id}`
  };
  return fetchData(
    options,
    z.object({
      gp_ods_code: z.string(),
      title: z.string(),
      first_name: z.string(),
      last_name: z.string(),
      email: z.null(),
      addline1: z.string(),
      addline2: z.string(),
      addline3: z.null(),
      addline4: z.null(),
      region: z.string(),
      postcode: z.string(),
      country: z.string()
    })
  );
};

// Get proxy requests
export const getProxyRequests = () => {
  const options = {
    params: 'proxy/requests/APPUSER'
  };
  return fetchData(options);
};

// Get proxy requests
export const submitProxyRequests = (postBody) => {
  const options = {
    params: 'proxy/request',
    postBody,
    method: 'POST'
  };
  return fetchData(options);
};

// Get all patient surveys
export const allSurveys = (request: {
  page?: number;
  rows?: number;
  complete?: string;
  case_id?: number;
  sort?: string;
  order?: string;
}) => {
  const queryString = Object.entries(request)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
  const options = {
    params: `surveys/surveys/APPUSER?${queryString}`,
    actionTypes: actionTypes.ALL_SURVEYS_SUCCESS,
    errorType: actionTypes.ALL_SURVEYS_FAIL
  };
  return fetchData(options);
};

// Create Case
export const createCase = (
  pat_guid: string,
  speccat: string,
  specarea: string,
  notes: string,
  caseUrgency?: string,
  case_ref?: string
) => {
  const options = {
    params: 'cases/new',
    postBody: {
      user_guid: pat_guid,
      pat_guid,
      speccat,
      specarea,
      auth_code: 1,
      current_notes: notes,
      urg_code: caseUrgency,
      client_case_id: case_ref
    },
    method: 'PUT'
  };
  return fetchData(options);
};

/** __Note:__ this will only work with Tap cases that have not progressed.
 *
 * `pathway_code` can be `'F2F'` or `'CR'` */
export const setCasePathway = (request: { user_guid: string; case_id: number; pathway_code: 'F2F' | 'CR' }) =>
  fetchData(
    {
      params: 'cases/pathway',
      method: 'POST',
      postBody: request,
      actionType: actionTypes.SET_CASE_PATHWAY_SUCCESS,
      errorType: actionTypes.SET_CASE_PATHWAY_FAIL
    },
    z.unknown()
  );

export const switchToFullAccount = (email) => {
  const options = {
    params: 'switch-full',
    method: 'POST',
    postBody: {
      email
    }
  };
  return fetchData(options);
};

export const getCIMARLink = ({ case_id, appt_id }) => {
  const options = {
    params: `cimar/link/APPUSER?${new URLSearchParams(case_id ? { case_id } : { appt_id }).toString()}`,
    method: 'GET'
  };
  return fetchData(options);
};

export const getSerenovaToken = () => {
  const options = {
    params: 'serenova/get-auth-token'
  };
  return fetchData(options);
};

export const getSerenovaContacts = (token, params) => {
  const { SERENOVA_TENANT } = publicRuntimeConfig;
  return async () => {
    const url = `https://eu-west-1-prod-api.cxengage.net/v1/tenants/${SERENOVA_TENANT}/contacts`;
    const headers = {
      Authorization: `Token ${token}`
    };
    return Axios.get(url, { headers, params });
  };
};

export const createSerenovaContact = (token, body) => {
  const { SERENOVA_TENANT } = publicRuntimeConfig;
  return async () => {
    const url = `https://eu-west-1-prod-api.cxengage.net/v1/tenants/${SERENOVA_TENANT}/contacts`;
    const headers = {
      Authorization: `Token ${token}`
    };
    return Axios.post(url, body, { headers });
  };
};

export const updateSerenovaContact = (token, contactId, body) => {
  const { SERENOVA_TENANT } = publicRuntimeConfig;
  return async () => {
    const url = `https://eu-west-1-prod-api.cxengage.net/v1/tenants/${SERENOVA_TENANT}/contacts/${contactId}`;
    const headers = {
      Authorization: `Token ${token}`
    };
    return Axios.put(url, body, { headers });
  };
};

export const stripeCreateCustomer = (name, phone, email, description) => {
  const options = {
    method: 'POST',
    params: `stripe-api/setup_intent?${new URLSearchParams({ name, phone, email, description }).toString()}`,
    postBody: {}
  };
  return fetchData(options);
};

export const stripeListCards = () => {
  const options = {
    method: 'GET',
    params: 'stripe-api/card_details'
  };
  return fetchData(options);
};

export const needsCardDetails = () => {
  const options = {
    method: 'GET',
    params: 'users/stripe-db/info/APPUSER'
  };
  return fetchData(options);
};

export const stripeCardSetupComplete = () => {
  const options = {
    method: 'POST',
    params: 'users/stripe-db/date/APPUSER',
    postBody: {}
  };
  return fetchData(options);
};

export type TimingEvent =
  | 'REGISTRATION_START'
  | 'REGISTRATION_END'
  | 'NAME_AND_EMAIL_START'
  | 'NAME_AND_EMAIL_END'
  | 'EMAIL_VERIFICATION_START'
  | 'EMAIL_VERIFICATION_END'
  | 'MOBILE_VERIFICATION_START'
  | 'MOBILE_VERIFICATION_END'
  | 'IDENTITY_INFO_START'
  | 'IDENTITY_INFO_END'
  | 'ADDRESS_INFO_START'
  | 'ADDRESS_INFO_END'
  | 'GP_INFO_START'
  | 'GP_INFO_END'
  | 'CC_START'
  | 'CC_END';

export const usersTimingEvent = (event: TimingEvent, date?: Date) => {
  const options = {
    method: 'POST',
    params: 'users/timing/event/APPUSER',
    postBody: { event, date }
  };
  return fetchData(options);
};

export const getToDoList = (caseId?: string) => {
  const options = {
    method: 'GET',
    params: caseId ? `users/todo/APPUSER/${caseId}` : 'users/todo/APPUSER/'
  };
  return fetchData(options, z.object({ todo: z.array(TodoSchema) }));
};

export const getIncompletedToDosList = (caseId?: string) => {
  const options = {
    method: 'GET',
    params: caseId ? `users/todo/APPUSER/${caseId}?complete=false` : 'users/todo/APPUSER/?complete=false'
  };
  return fetchData(options, z.object({ todo: z.array(TodoSchema) }));
};

export const getNextSurvey = (caseId: number) => {
  const options = {
    method: 'GET',
    params: `users/todo/APPUSER/${caseId}?type=surveyjs&complete=false`
  };
  return fetchData(options);
};
